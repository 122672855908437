.allplayerskeleton {
  .dknajr {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 100%;
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffffff;
  }
  .caNzNe {
    display: flex;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 500;
  }
  .hudJpJ {
    display: flex;
    align-items: stretch;
    width: 100%;
    background-color: #ffffff;
    min-height: 52px;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.12);
    border-bottom-style: solid;
  }
  .gPJqny {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    border: 1px solid #ccc;
    color: #fff;
  }
  .imMSkv {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    word-break: break-word;
    border: 1px solid #ccc;
    color: #fff;
  }
  .elpSoT {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    max-width: 80px;
    min-width: 100px;
    justify-content: center;
  }
  .dQcPXM {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 100px;
  }
  .kPFmFZ {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    max-width: 200px;
    min-width: 100px;
    justify-content: center;
  }
  .bZgVeN {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    max-width: 100px;
    min-width: 100px;
  }
  .bOmZtP {
    div {
      &:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .kQSTYI {
    display: flex;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffffff;
    min-height: 48px;
    &:not(:last-of-type) {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: rgba(0, 0, 0, 0.12);
    }
    &:hover {
      color: rgba(0, 0, 0, 0.87);
      background-color: #eeeeee;
      transition-duration: 0.15s;
      transition-property: background-color;
      border-bottom-color: #ffffff;
      outline-style: solid;
      outline-width: 1px;
      outline-color: #ffffff;
    }
  }
  .eaKQqZ {
    padding: 2px;
    color: inherit;
    flex-grow: 0;
    flex-shrink: 0;
    opacity: 0;
  }
  .bAhklE {
    display: inline-flex;
    align-items: center;
    justify-content: inherit;
    height: 100%;
    width: 100%;
    outline: none;
    user-select: none;
    overflow: hidden;
  }
  .kOrdYE {
    display: inline-flex;
    align-items: center;
    justify-content: inherit;
    height: 100%;
    width: 100%;
    outline: none;
    user-select: none;
    overflow: hidden;
    cursor: pointer;
    span.__rdt_custom_sort_icon__ {
      i {
        transform: "translate3d(0, 0, 0)";
        opacity: 0;
        color: inherit;
        font-size: 18px;
        height: 18px;
        width: 18px;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        transition-duration: 95ms;
        transition-property: transform;
      }
      svg {
        transform: "translate3d(0, 0, 0)";
        opacity: 0;
        color: inherit;
        font-size: 18px;
        height: 18px;
        width: 18px;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        transition-duration: 95ms;
        transition-property: transform;
      }
    }
    span.__rdt_custom_sort_icon__.asc {
      i {
        transform: rotate(180deg);
      }
      svg {
        transform: rotate(180deg);
      }
    }
    &:hover {
      opacity: 0.7;
      span {
        opacity: 0.7;
      }
      span.__rdt_custom_sort_icon__ {
        * {
          opacity: 0.7;
        }
      }
    }
    &:focus {
      opacity: 0.7;
      span {
        opacity: 0.7;
      }
      span.__rdt_custom_sort_icon__ {
        * {
          opacity: 0.7;
        }
      }
    }
  }
  .ddvZdT {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .htmdXE {
    display: flex;
    flex-direction: column;
  }
  .hZZlND {
    position: relative;
    width: 100%;
    border-radius: inherit;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 0;
  }
  .yGWof {
    position: relative;
    width: 100%;
    display: table;
  }
  .bEOvvC {
    cursor: pointer;
    height: 24px;
    max-width: 100%;
    user-select: none;
    padding-left: 8px;
    padding-right: 24px;
    box-sizing: content-box;
    font-size: inherit;
    color: inherit;
    border: none;
    background-color: transparent;
    appearance: none;
    direction: ltr;
    flex-shrink: 0;
    &::-ms-expand {
      display: none;
    }
    &:disabled {
      &::-ms-expand {
        background: #f60;
      }
    }
    option {
      color: initial;
    }
  }
  .bpcekY {
    position: relative;
    flex-shrink: 0;
    font-size: inherit;
    color: inherit;
    margin-top: 1px;
    svg {
      top: 0;
      right: 0;
      color: inherit;
      position: absolute;
      fill: currentColor;
      width: 24px;
      height: 24px;
      display: inline-block;
      user-select: none;
      pointer-events: none;
    }
  }
  .hDPlLz {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding-right: 8px;
    padding-left: 8px;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
    min-height: 56px;
    background-color: #ffffff;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: rgba(0, 0, 0, 0.12);
  }
  .cPTFZC {
    position: relative;
    display: block;
    user-select: none;
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 8px;
    margin: px;
    cursor: pointer;
    transition: 0.4s;
    color: rgba(0, 0, 0, 0.54);
    fill: rgba(0, 0, 0, 0.54);
    background-color: transparent;
    &:disabled {
      cursor: unset;
      color: rgba(0, 0, 0, 0.18);
      fill: rgba(0, 0, 0, 0.18);
    }
    &:hover {
      &:not(:disabled) {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
    &:focus {
      outline: none;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
  .eLgGMP {
    display: flex;
    align-items: center;
    border-radius: 4px;
    white-space: nowrap;
  }
  .hrIAxQ {
    flex-shrink: 1;
    user-select: none;
  }
  .jEvKKB {
    margin: 0 24px;
  }
  .kfEVfC {
    margin: 0 4px;
  }
  @media screen and (max-width: 599px) {
    .eLgGMP {
      width: 100%;
      justify-content: space-around;
    }
  }
}
