// Import fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shojumaru&display=swap");
// Variables
$primary-color: rgba(33, 72, 192, 1);
$secondary-color: rgba(255, 255, 255, 1);
$black-color: #292929;
$purple-color: rgba(16, 14, 52, 1);
$hover-color: #0056b3;
$hover-pink: rgba(231, 50, 147, 1);
$font-main: "Montserrat", sans-serif;
$font-secondary: "Roboto", sans-serif;
$font-tertiary: "Inter", sans-serif;
$font-size-base: 1rem;
$font-size-small: 0.9rem;
$font-weight-bold: 700;
$font-weight-light: 300;
$input-border-color: rgba(255, 255, 255, 1);
$padding-input: 15px;
$padding-button: 12px 20px;
$height-full: 100vh;
$border-thick: 1px solid rgb(0, 0, 0);
$border-thin: 1px solid rgba(0, 0, 0, 0.178);

// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin box-shadow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.7);
}

@mixin button-style($bg-color: $secondary-color, $color: $primary-color) {
  width: 100%;
  border: none;
  padding: $padding-button;
  background-color: $bg-color;
  color: $color;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: $font-weight-bold;
  border-radius: 4px;
}

@mixin back-button-style($bg-color: $secondary-color, $color: $primary-color) {
  background-color: $bg-color;
  color: $black-color;
  border: none;
  padding: 9px 15px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: $font-weight-bold;
  &:focus {
    background-color: $bg-color;
  }
  &:hover {
    background-color: $bg-color;
    color: $black-color;
  }
  &:active {
    background-color: $bg-color !important;
    color: $black-color !important;
  }
}

.font-main {
  font-family: $font-main;
}

.font-secondary {
  font-family: $font-secondary;
}

.font-tertiary {
  font-family: $font-tertiary;
}

.btn-default {
  @include button-style();
  font-size: $font-size-small - 0.2rem;
  padding: 5px 12px;
  border: none;
  background-color: $hover-pink;
  color: $secondary-color;
  &:hover {
    background-color: $secondary-color !important;
    color: $hover-pink !important;
  }
  &:active {
    background-color: $secondary-color !important;
    color: $hover-pink !important;
  }
  &:focus {
    background-color: $secondary-color !important;
    color: $hover-pink !important;
  }
}
.back-button {
  @include back-button-style;
}

// -------------------Switch---------------------------
.react-switch-checkbox {
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
}

.react-switch {
  cursor: pointer;
  width: 34px;
  height: 17px;
  border-radius: 50%;
  background: #fff;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch .react-switch-button {
  position: absolute;
  z-index: 1;
  // top: -1px;
  // left: 0px;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  background: #ffffff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-checkbox:active + .react-switch-button {
  width: 60px;
}

.react-switch-labels {
  display: relative;
  z-index: 0;
  height: 100%;
  font: 6pt "Segoe UI", Verdana, sans-serif;
}

.react-switch-labels span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}
.react-switch-labels span:first-child {
  left: 2px;
}
.react-switch-labels span:last-child {
  right: 4px;
}

// ----------------------------------------------------

@mixin input-group-style {
  .input-group-text {
    border: 0;
    background: transparent;
    padding: $padding-input;
  }
  .form-control {
    border: 0;
    color: $secondary-color;
    background: transparent;
    font-weight: $font-weight-light;
    font-size: $font-size-base;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: $secondary-color;
    }
  }
  border: 1px solid $input-border-color;
  border-radius: 4px;
}

// Navbar
.navbar-component {
  height: 70px;
  .container {
    border-bottom: 1px solid $secondary-color;
    padding-bottom: 10px;
  }
  .navbar-brand {
    font-family: $font-secondary;
    font-weight: $font-weight-bold;
    color: $secondary-color;
  }
  .navbar-profile {
    .dropdown-toggle {
      color: $secondary-color;
    }
    .dropdown-toggle:focus {
      box-shadow: none;
      border: none;
      color: $secondary-color;
    }
    .dropdown-menu {
      width: 270px;
      font-family: $font-tertiary;
      font-size: $font-size-small;
      font-weight: $font-weight-bold - 200;
      p {
        font-size: $font-size-small - 0.1rem;
        font-weight: $font-weight-light - 500;
      }
    }
    .dropdown-item {
      @include flex-center;
      justify-content: start;
      margin: 10px 0;
      padding: 0;
      font-family: $font-tertiary;
      font-size: $font-size-small;
      font-weight: $font-weight-bold - 200;
      a {
        text-decoration: none;
        color: $black-color;
      }
    }
    .dropdown-item:focus,
    .dropdown-item:hover,
    .dropdown-item:active {
      background-color: transparent;
      color: $black-color;
    }
    .dropdown-menu[data-bs-popper] {
      top: 100%;
      right: 0;
      left: auto;
      margin-top: var(--bs-dropdown-spacer);
    }
  }
}

// Generic form style
@mixin form-style {
  font-family: $font-main;
  font-optical-sizing: auto;
  font-style: normal;
  .input-group {
    @include input-group-style;
  }
  .forgot-password-link {
    color: $secondary-color;
    font-size: $font-size-small;
    text-decoration: none;
    transition: color 0.2s;
    cursor: pointer;
    &:hover {
      color: $hover-pink; // Change hover color as needed
    }
  }
}

.heading {
  font-size: $font-size-base + 0.2rem;
  font-weight: $font-weight-bold;
  border-bottom: $border-thin;
  border-color: rgba(255, 255, 255, 0.822);
  margin-bottom: 10px;
  color: #fff;
}

// Generic page layout
@mixin page-layout {
  min-height: $height-full;
  max-height: auto;
  font-family: $font-main;
}

@mixin list-container() {
  .list-container {
    padding: 20px 30px;
    background-color: $purple-color;
    color: $secondary-color;
    border-radius: 15px;
    .heading {
      font-size: $font-size-base + 0.2rem;
      font-weight: $font-weight-bold;
      border-bottom: $border-thin;
      border-color: rgba(255, 255, 255, 0.822);
      margin-bottom: 10px;
    }
    .item-number {
      font-size: $font-size-small;
      font-weight: $font-weight-bold;
      margin-bottom: 0;
      margin-right: 10px;
    }
    p {
      font-size: $font-size-small;
      font-weight: $font-weight-light - 500;
    }
    .item-container {
      border: $border-thin;
      border-color: rgba(255, 255, 255, 0.822);
      border-radius: 5px;
      padding: 2px 15px;
      padding-bottom: 10px !important;
      height: 330px;
      overflow-y: scroll;
      // Hide scrollbar when not needed

      &::-webkit-scrollbar {
        width: 4px;
        background-color: rgb(46, 46, 46);
        border-radius: 10px;
        padding-top: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(231, 50, 147, 1);
        border-radius: 5px;
      }
      .list-item {
        @include flex-center;
        justify-content: space-between;
        border-bottom: $border-thin;
        border-color: rgba(255, 255, 255, 0.822);
        padding: 8px 0;
        .item-content {
          @include flex-center;
          margin: 0;
          .form-check-input {
            width: 25px;
            height: 25px;
            margin-bottom: 0;
            background-color: transparent;
            border: $border-thin;
            border-color: rgba(255, 255, 255, 0.822);
            box-shadow: 0 0 0 0.25rem rgba(231, 50, 147, 0.25);
          }
          img {
            border-radius: 40px;
            // width: 40px;
            margin: 0 15px;
          }
          .team-name {
            font-size: $font-size-base;
            font-weight: $font-weight-bold;
            margin-bottom: 0;
          }
        }
        .item-buttons {
          @include flex-center;
          .btn-default {
            @include button-style();
            font-size: $font-size-small - 0.2rem;
            padding: 5px 12px;
            border: none;
            background-color: $hover-pink;
            color: $secondary-color;
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.button-group-pink-white {
  @include flex-center;
  justify-content: start;
  background-color: transparent;
  .button-pink-white {
    @include flex-center;
    font-size: 15px;
    font-weight: $font-weight-bold;
    padding: 6px 18px;
    border: none;
  }
}
//image uploading

.logo-container {
  @include flex-center;
}

// Components with specific changes
#login,
#newPassword,
#otp,
#forgetPassword {
  @include page-layout;
  @include flex-center;

  .newPassword {
    font-family: $font-secondary;
    font-weight: $font-weight-bold;
    color: $secondary-color;
  }
  .text {
    font-family: $font-secondary;
    font-weight: $font-weight-light;
    color: $secondary-color;
  }

  .otp-container {
    justify-content: center;
    input {
      background: transparent !important;
      border: 1px solid $secondary-color;
      font-size: 2rem;
      color: $secondary-color;
      width: 60px !important;
      height: 60px !important;
    }
  }
  .timer {
    color: rgb(146, 0, 61);
  }
  form {
    @include form-style;
  }
  .btn-default {
    @include button-style();
  }
}

// Dashboard

#dashboard {
  .container {
    .buttons-container {
      height: calc(100vh - 70px);
      @include flex-center;
    }
  }
  .btn-default {
    @include button-style();
  }
}

// Start Match
#startMatch {
  @include page-layout;
  .logo-table-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .table-container {
      background-color: $purple-color;
      @include box-shadow;
      width: 100%;
      max-width: 90vw; // Reduced width for smaller screen sizes
      // max-height: 75vh; // Reduced height to fit within the viewport
      padding: 15px 25px; // Reduced padding for better fit
      border-radius: 10px; // Slightly smaller radius to save space
      // overflow-y: scroll;

      // Hide scrollbar
      &::-webkit-scrollbar {
        display: none;
      }

      // Center the heading and make it responsive
      .heading {
        font-size: $font-size-base + 0.2rem; // Reduced the font size slightly
        color: $secondary-color;
        font-weight: $font-weight-bold;
        border-bottom: 1px solid $secondary-color;
        margin-bottom: 10px;
      }

      .hZZlND {
        border-radius: 0;
      }

      .rdt_Table {
        background-color: transparent;
        border: 1px solid $secondary-color;
        border-radius: 0;

        .rdt_TableHeadRow {
          background-color: transparent;
          color: $secondary-color;
          font-size: $font-size-small; // Reduced font size for table headings
          font-weight: $font-weight-bold;
          border-bottom: 1px solid $secondary-color;
          min-height: 35px;

          .rdt_TableCol {
            border-right: 1px solid $secondary-color;
            justify-content: center;
            &:nth-child(1) {
              justify-content: center;
            }
            &:nth-child(2) {
              justify-content: flex-start;
            }
            span {
              display: none;
            }
          }
        }

        .rdt_TableBody {
          .rdt_TableRow {
            border-color: $secondary-color;
            background-color: transparent;
            min-height: 35px;
          }
        }
      }

      .rdt_Pagination {
        background-color: transparent;
        color: $secondary-color;
        font-size: $font-size-small - 0.2rem; // Reduced font size for pagination
        padding: 5px; // Reduced padding for pagination
        svg {
          fill: $secondary-color;
        }
      }
    }
  }

  .btn-default {
    @include back-button-style();
  }
}

.iXKRic {
  justify-content: center;
}

.fPjfHA {
  justify-content: center;
}

//startMatchScreen
#startMatchScreen {
  @include page-layout;

  .image-input-container {
    width: 400px;
    .input-group {
      .react-datepicker-wrapper {
        display: flex;
        align-items: center;
      }
      @include input-group-style;

      input[type="date"]::-webkit-inner-spin-button,
      input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
      .form-select {
        background-color: transparent;
        border: none;
        box-sizing: none;
        color: $secondary-color;
        box-shadow: none;
        font-weight: $font-weight-light;
        font-size: $font-size-base;

        option {
          background-color: $purple-color;
          color: $secondary-color;
          font-weight: $font-weight-light;
          font-size: $font-size-base;
        }
      }
    }

    .btn-default {
      @include button-style();
    }
  }

  .css-13cymwt-control,
  .css-t3ipsp-control {
    background-color: transparent !important;
    border: none !important;
    box-sizing: none;
    color: $secondary-color;
    box-shadow: none;
    font-weight: $font-weight-light;
    font-size: $font-size-base;
    border: 1px solid $input-border-color;
    padding: 8px 0;
  }

  .table-container {
    background-color: $purple-color;
    @include box-shadow;
    width: 100%;
    max-width: 90vw; // Reduced width for smaller screen sizes
    // max-height: 75vh; // Reduced height to fit within the viewport
    padding: 15px 25px; // Reduced padding for better fit
    border-radius: 10px; // Slightly smaller radius to save space
    // overflow-y: scroll;

    // Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }

    // Center the heading and make it responsive
    .heading {
      font-size: $font-size-base + 0.2rem; // Reduced the font size slightly
      color: $secondary-color;
      font-weight: $font-weight-bold;
      border-bottom: 1px solid $secondary-color;
      margin-bottom: 10px;
    }

    .hZZlND {
      border-radius: 0;
    }

    .rdt_Table {
      background-color: transparent;
      border: 1px solid $secondary-color;
      border-radius: 0;

      .rdt_TableHeadRow {
        background-color: transparent;
        color: $secondary-color;
        font-size: $font-size-small; // Reduced font size for table headings
        font-weight: $font-weight-bold;
        border-bottom: 1px solid $secondary-color;
        min-height: 35px;

        .rdt_TableCol {
          border-right: 1px solid $secondary-color;
          span {
            display: none;
          }
        }
      }

      .rdt_TableBody {
        .rdt_TableRow {
          border-color: $secondary-color;
          background-color: transparent;
          min-height: 35px;
        }
      }
    }

    .rdt_Pagination {
      background-color: transparent;
      color: $secondary-color;
      font-size: $font-size-small - 0.2rem; // Reduced font size for pagination
      padding: 5px; // Reduced padding for pagination
      svg {
        fill: $secondary-color;
      }
    }

    .form-check-input {
      width: 15px;
      height: 15px;
      margin-bottom: 0;
      background-color: transparent;
      border: $border-thin;
      border-color: rgba(255, 255, 255, 0.822);
      box-shadow: 0 0 0 0.25rem rgba(231, 50, 147, 0.25);
    }
  }
}

//All Tournament
#allTournament {
  @include page-layout;
  .logo-table-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .table-container {
      background-color: $purple-color;
      @include box-shadow;
      width: 100%;
      max-width: 90vw; // Reduced width for smaller screen sizes
      // max-height: 75vh; // Reduced height to fit within the viewport
      padding: 15px 25px; // Reduced padding for better fit
      border-radius: 10px; // Slightly smaller radius to save space
      // overflow-y: scroll;

      // Hide scrollbar
      &::-webkit-scrollbar {
        display: none;
      }

      // Center the heading and make it responsive
      .heading {
        font-size: $font-size-base + 0.2rem; // Reduced the font size slightly
        color: $secondary-color;
        font-weight: $font-weight-bold;
        border-bottom: 1px solid $secondary-color;
        margin-bottom: 10px;
      }

      .hZZlND {
        border-radius: 0;
      }

      .imMSkv {
      }

      .rdt_Table {
        background-color: transparent;
        border: 1px solid $secondary-color;
        border-radius: 0;

        .rdt_TableHeadRow {
          background-color: transparent;
          color: $secondary-color;
          font-size: $font-size-small; // Reduced font size for table headings
          font-weight: $font-weight-bold;
          border-bottom: 1px solid $secondary-color;
          min-height: 35px;

          .rdt_TableCol {
            border-right: 1px solid $secondary-color;
            justify-content: center;
            &:nth-child(1) {
              justify-content: center;
            }
            &:nth-child(2) {
              justify-content: space-between;
              text-align: left;

              p {
                margin: 0;
                flex: 1; // Occupies all available space on the left
                text-align: start; // Ensures the text stays left-aligned
                word-break: break-word;
              }

              svg {
                margin-left: auto; // Pushes the icon to the far right
                cursor: pointer;
              }
            }
            span {
              display: none;
            }
          }
        }

        .rdt_TableBody {
          .rdt_TableRow {
            border-color: $secondary-color;
            background-color: transparent;
            // min-height: 35px;
          }
        }
      }

      .rdt_Pagination {
        background-color: transparent;
        color: $secondary-color;
        font-size: $font-size-small - 0.2rem; // Reduced font size for pagination
        padding: 5px; // Reduced padding for pagination
        svg {
          fill: $secondary-color;
        }
      }
    }
  }

  .btn-default {
    @include back-button-style();
  }
}

// Profile Settings
#profileSettings {
  @include page-layout;
  .logo-cards-container {
    @include flex-center;
    flex-direction: column;
  }
  .profile-card {
    background-color: $secondary-color;
    @include box-shadow;
    // width: 30%;
    padding: 10px;
    border-radius: 10px;
    .row {
      padding: 12px 15px;
      border-bottom: $border-thin;
      .col-lg-2 {
        @include flex-center;
        flex-direction: column;
        button {
          @include flex-center;
          background-color: $hover-pink;
          border: none;
          border-radius: 4px;
          font-size: $font-size-small - 0.5rem;
          padding: 2px 12px;
          width: 100;
        }
      }
      .col-lg-10 {
        @include flex-center;
        flex-direction: column;
        align-items: start;
      }
    }

    h3 {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      margin: 0;
    }
    p {
      font-size: $font-size-small - 0.2rem;
      font-weight: $font-weight-light;
      margin: 0;
    }
    &.v2 {
      .text-input-container {
        input {
          &::placeholder {
            color: #fff;
          }
        }
      }
    }
    .text-input-container {
      @include flex-center;
      padding: 10px 0;
      border-bottom: $border-thin;
      p {
        font-size: $font-size-small - 0.2rem;
        font-weight: $font-weight-bold;
        margin: 0;
      }

      input {
        background: transparent !important;
        border: none;
        padding: 0;
        margin: 0;
        font-size: $font-size-small - 0.2rem;
        text-align: right;
        box-shadow: none;
        background-image: unset !important;
      }
    }
  }
}

// User Management
#userManagement {
  @include page-layout;
  .container {
    .row {
      .user-card-container {
        @include flex-center;
        flex-direction: column;
        text-align: center;
        background-color: $secondary-color;
        width: 200px;
        // height: 244px;
        border-radius: 10px;
        .card {
          height: 100%;
          padding: 10px 0;
          border: 0;
          width: 100%;
        }
        .card-body,
        .card-footer {
          padding: 0;
          border: 0;
          width: 100%;
          background-color: transparent;
        }
        .card-title {
          font-size: $font-size-base;
          margin-bottom: 5px;
          margin-top: 2px;
        }
        .card-text {
          font-size: $font-size-small - 0.2rem;
        }
        .button-container {
          @include flex-center;
          margin-top: 15px;
          justify-content: space-around;
          .btn {
            font-size: $font-size-small - 0.2rem;
            padding: 2px 12px;
            border: none;
          }
          .btn-apply {
            background-color: rgba(86, 195, 97, 0.9);
          }
          .btn-editAccess {
            background-color: rgba(168, 22, 22, 0.7);
          }
        }
      }
    }
  }
}

// #createTournament {
//   @include page-layout;

//   .image-input-container {
//     // width: 400px;
//     .input-group {
//       .react-datepicker-wrapper {
//         display: flex;
//         align-items: center;
//       }
//       @include input-group-style;

//       input[type="date"]::-webkit-inner-spin-button,
//       input[type="date"]::-webkit-calendar-picker-indicator {
//         display: none;
//         -webkit-appearance: none;
//       }
//       .form-select {
//         background-color: transparent;
//         border: none;
//         box-sizing: none;
//         color: $secondary-color;
//         box-shadow: none;
//         font-weight: $font-weight-light;
//         font-size: $font-size-base;

//         option {
//           background-color: $purple-color;
//           color: $secondary-color;
//           font-weight: $font-weight-light;
//           font-size: $font-size-base;
//         }
//       }
//     }

//     .btn-default {
//       @include button-style();
//     }
//   }
// }

#createEditTournament {
  @include page-layout;

  .image-input-container {
    width: 400px;
    .input-group {
      .react-datepicker-wrapper {
        display: flex;
        align-items: center;
      }
      @include input-group-style;

      input[type="date"]::-webkit-inner-spin-button,
      input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
      .form-select {
        background-color: transparent;
        border: none;
        box-sizing: none;
        color: $secondary-color;
        box-shadow: none;
        font-weight: $font-weight-light;
        font-size: $font-size-base;

        option {
          background-color: $purple-color;
          color: $secondary-color;
          font-weight: $font-weight-light;
          font-size: $font-size-base;
        }
      }
    }

    .btn-default {
      @include button-style();
    }
  }

  .table-container {
    background-color: $purple-color;
    @include box-shadow;
    width: 100%;
    max-width: 90vw; // Reduced width for smaller screen sizes
    // max-height: 75vh; // Reduced height to fit within the viewport
    padding: 15px 25px; // Reduced padding for better fit
    border-radius: 10px; // Slightly smaller radius to save space
    // overflow-y: scroll;

    // Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }

    // Center the heading and make it responsive
    .heading {
      font-size: $font-size-base + 0.2rem; // Reduced the font size slightly
      color: $secondary-color;
      font-weight: $font-weight-bold;
      border-bottom: 1px solid $secondary-color;
      margin-bottom: 10px;
    }

    .hZZlND {
      border-radius: 0;
    }

    .rdt_Table {
      background-color: transparent;
      border: 1px solid $secondary-color;
      border-radius: 0;

      .rdt_TableHeadRow {
        background-color: transparent;
        color: $secondary-color;
        font-size: $font-size-small; // Reduced font size for table headings
        font-weight: $font-weight-bold;
        border-bottom: 1px solid $secondary-color;
        min-height: 35px;

        .rdt_TableCol {
          border-right: 1px solid $secondary-color;
          span {
            display: none;
          }
        }
      }

      .rdt_TableBody {
        .rdt_TableRow {
          border-color: $secondary-color;
          background-color: transparent;
          min-height: 35px;
        }
      }
    }

    .rdt_Pagination {
      background-color: transparent;
      color: $secondary-color;
      font-size: $font-size-small - 0.2rem; // Reduced font size for pagination
      padding: 5px; // Reduced padding for pagination
      svg {
        fill: $secondary-color;
      }
    }

    .form-check-input {
      width: 15px;
      height: 15px;
      margin-bottom: 0;
      background-color: transparent;
      border: $border-thin;
      border-color: rgba(255, 255, 255, 0.822);
      box-shadow: 0 0 0 0.25rem rgba(231, 50, 147, 0.25);
    }
  }
}

#teamSelection {
  @include page-layout;
  @include list-container();
}

.dashboard-button-icon {
  filter: invert(18%) sepia(78%) saturate(2700%) hue-rotate(220deg)
    brightness(90%) contrast(105%);
}

#addNewPlayer {
  @include page-layout;
  @include list-container();

  .logo-table-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .table-container {
      background-color: $purple-color;
      @include box-shadow;
      width: 100%;
      max-width: 90vw; // Reduced width for smaller screen sizes
      // max-height: 75vh; // Reduced height to fit within the viewport
      padding: 15px 25px; // Reduced padding for better fit
      border-radius: 10px; // Slightly smaller radius to save space
      // overflow-y: scroll;

      // Hide scrollbar
      &::-webkit-scrollbar {
        display: none;
      }

      // Center the heading and make it responsive
      .heading {
        font-size: $font-size-base; // Reduced the font size slightly
        color: $secondary-color;
        font-weight: $font-weight-bold;
        border-bottom: 1px solid $secondary-color;
        margin-bottom: 10px;
      }

      .hZZlND {
        border-radius: 0;
      }

      .rdt_Table {
        background-color: transparent;
        border: 1px solid $secondary-color;
        border-radius: 0;

        .rdt_TableHeadRow {
          background-color: transparent;
          color: $secondary-color;
          font-size: $font-size-small; // Reduced font size for table headings
          font-weight: $font-weight-bold;
          border-bottom: 1px solid $secondary-color;
          min-height: 35px;

          .rdt_TableCol {
            border-right: 1px solid $secondary-color;
            justify-content: center;
            &:nth-child(1) {
              justify-content: center;
            }
            &:nth-child(2) {
              justify-content: center;
            }
            &:nth-child(3) {
              justify-content: flex-start;
            }
            span {
              display: none;
            }
          }
        }

        .rdt_TableBody {
          .rdt_TableRow {
            border-color: $secondary-color;
            background-color: transparent;
            min-height: 35px;
            &:nth-child(1) {
              justify-content: center;
            }
          }
        }
      }

      .rdt_Pagination {
        background-color: transparent;
        color: $secondary-color;
        font-size: $font-size-small - 0.2rem; // Reduced font size for pagination
        padding: 5px; // Reduced padding for pagination
        svg {
          fill: $secondary-color;
        }
      }
    }
    .jEzKnw {
      justify-content: center;
    }
  }
  .form-check-input {
    width: 25px;
    height: 25px;
    margin-bottom: 0;
    background-color: transparent;
    border: $border-thin;
    border-color: rgba(255, 255, 255, 0.822);
    box-shadow: 0 0 0 0.25rem rgba(231, 50, 147, 0.25);
  }
}

.teams-modal {
  .modal-content {
    background-color: transparent;
    @include list-container();
  }
}

#createTeam {
  @include page-layout;
  @include list-container();

  .list-container {
    padding: 10px 20px;
    margin-top: 20px;
  }
  .row {
    align-items: baseline;
    padding-top: 15px;
    .form-check-label {
      font-size: $font-size-small - 0.1rem;
      font-weight: normal;
    }
    .form-control {
      border: $border-thick;
      border-color: rgba(255, 255, 255, 0.822);
      border-radius: 5px;
      padding: 2px 15px;
      background-color: transparent;
      color: white;
      box-shadow: none;
      font-size: $font-size-small - 0.1rem;
    }
    .heading {
      p {
        font-size: $font-size-base - 0.2rem;
        font-weight: $font-weight-bold;
      }
    }

    .col-lg-10 {
      border: 0;
    }
    .col-lg-2 {
      @include flex-center;
    }
  }
}

//existing players skeleton loader
.skeleton {
  background-color: #ddd;
  border-radius: 4px;
  min-height: 20px;
  width: 100%;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-text {
  height: 20px;
  width: 50%;
  margin-bottom: 10px;
}

.skeleton-image {
  height: 144px;
  width: 120px;
  background-color: #ddd;
  position: absolute;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#previous {
  @include page-layout;
  @include list-container();
}

#playerStatsReport {
  // @include page-layout;
  .heading {
    font-size: $font-size-base + 0.2rem;
    font-weight: $font-weight-bold;
    margin-bottom: 10px;
    color: #fff;
    border: 0;
  }
  .playerStats-container {
    .year-tournament-container {
      border-bottom: $border-thin;
      border-color: rgba(255, 255, 255, 0.822);
      .input-group {
        @include input-group-style;
        width: inherit;
        padding: 5px;
        // padding-left: 1px;
        .input-group-text {
          padding: 0;
        }
      }
    }
    .input-options {
      .input-group {
        @include input-group-style;
        width: inherit;
        .form-select {
          background-color: transparent;
          border: none;
          box-sizing: none;
          color: $secondary-color;
          box-shadow: none;
          font-weight: $font-weight-light;
          font-size: $font-size-base;

          option {
            background-color: $purple-color;
            color: $secondary-color;
            font-weight: $font-weight-light;
            font-size: $font-size-base;
          }
        }
      }
    }
    .table-container {
      width: 100%;
      margin-top: 20px;
      padding: 15px;
      border-radius: 10px;
      // max-width: 90vw;
      overflow-x: auto;
      background-color: $purple-color;
      .heading {
        border-bottom: $border-thin;
        border-color: rgba(255, 255, 255, 0.822);
      }
      .hZZlND {
        border: 1px solid rgba(255, 255, 255, 0.822);
        border-radius: 0;
        .rdt_TableCol {
          justify-content: center;
        }
        .rdt_TableHeadRow {
          background-color: $purple-color;
          color: $secondary-color;
          border-bottom-color: #fff;
          font-size: $font-size-base;
        }
        .rdt_TableRow {
          background-color: $purple-color;
          color: $secondary-color;
          border-bottom-color: #fff;
        }
        //scrollbar style
        .rdt_TableBody::-webkit-scrollbar {
          width: 5px;
          height: 10px;
        }
        .rdt_TableBody::-webkit-scrollbar-track {
          background-color: $purple-color;
          border-radius: 5px;
        }
        .rdt_TableBody::-webkit-scrollbar-thumb {
          background-color: $secondary-color;
          border-radius: 5px;
        }
      }
    }
  }
}

// .off-canvas-body{
//   display: flex;
//   flex-direction: column;
//   align-items: start;
//   button{
//     background-color: transparent;
//     border: none;
//     font-family: $font-secondary;
//     font-size: 32px;
//     line-height: 70px;
//     color: #fff;

//   }:hover{
//     background-color: transparent;
//     color: rgba(231, 50, 147, 1);
//   }
// }
.off-canvas-body {
  display: flex;
  flex-direction: column;
  align-items: start;

  button {
    position: relative;
    background-color: transparent;
    border: none;
    font-family: $font-secondary;
    font-size: 1.2rem;
    line-height: 2.7rem;
    color: #fff;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 3px; // Thickness of the line
      background-color: rgba(231, 50, 147, 1);
      transition: width 0.3s ease-in-out; // Smooth animation
    }

    &:hover,
    &:focus {
      color: rgba(231, 50, 147, 1);

      &::after {
        width: 100%;
      }
    }
  }
}

#matchScoring {
  // background-color: rgba(9, 8, 31, 1);
  color: $secondary-color;

  .form-select {
    background-color: transparent;
    border: none;
    box-sizing: none;
    color: $secondary-color;
    box-shadow: none;
    font-weight: $font-weight-light;
    font-size: $font-size-base;
    width: fit-content;

    option {
      background-color: $purple-color;
      color: $secondary-color;
      font-weight: $font-weight-light;
      font-size: 12px;
    }
  }

  .header {
    height: 7vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 30px;
    margin-left: 10px;
    .header-font {

      font-family: $font-secondary;
      font-size: 1.2rem;
      font-weight: 400;
      
      margin: 0;
    }
    .vs {
      font-family: "Shojumaru", system-ui;
      font-weight: 400;
      font-size: 1.2rem;
      color: rgba(231, 50, 147, 1);
      margin: 0;
    }
    .header-sec-font {
      font-family: $font-secondary;
      font-size: 0.7rem;
      font-weight: 400;
      margin: 0;
      word-break: break-all;
    }
  }

  .camera-section {
  }

  .left-layout {
    .scoring-buttons {
      border: 1px solid rgba(255, 255, 255, 0.822);
      border-radius: 10px;
      .heading-buttons-line {
        @include flex-center;
        justify-content: space-between;
        border-bottom: $border-thin;
        border-color: rgba(255, 255, 255, 0.822);
        padding: 5px 0;

        .btn-heading {
          font-family: $font-secondary;
          font-size: 0.8rem;
          font-weight: 400;
          width: 130px;
        }
        .btns-options {
          width: 654.99px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .btn {
            font-family: $font-secondary;
          }
        }
      }
      .heading-buttons-line:last-child {
        border-bottom: none;
      }
    }
    .table-container {
      border-radius: 10px;
      border: $border-thin;
      // overflow-x: auto;
      background-color: rgba(9, 8, 31, 1);

      //custom horizontal scrollbar
      .hZZlND::-webkit-scrollbar {
        height: 5px; // Adjust scrollbar height
        width: 5px;
      }
      .hZZlND::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50px;
      }
      .hZZlND::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50px;
      }

      .hZZlND {
        border: 1px solid rgba(255, 255, 255, 0.822);
        border-radius: 10px;
        height: 12vh;
        overflow-y: auto;

        .rdt_TableCol {
          justify-content: center;
          border-right: 1px solid rgba(255, 255, 255, 0.822);
        }
        .rdt_TableHeadRow {
          background-color: rgba(9, 8, 31, 1);
          color: $secondary-color;
          border-bottom-color: #fff;
          font-size: 0.5rem;
          min-height: 20px;
        }
        .rdt_TableRow {
          background-color: rgba(9, 8, 31, 1);
          color: $secondary-color;
          border-bottom: none;
          font-size: 0.5rem;
          min-height: 20px;
        }
        //scrollbar style
        .rdt_TableBody::-webkit-scrollbar {
          width: 5px;
          height: 10px;
        }
        .rdt_TableBody::-webkit-scrollbar-track {
          background-color: $purple-color;
          border-radius: 5px;
        }
      }
    }
  }

  .overs {
    background-color: #fff;
    width: 18px;
    color: $black-color;
    @include flex-center();
    border-radius: 100px;
    font-weight: 700;
    font-family: $font-secondary;
    font-size: 0.8rem;
    margin-top: 5px;
    margin-right: 5px;
  }
  .heading-options-line {
    border: $border-thin;
    border-color: rgba(255, 255, 255, 0.822);
    border-radius: 10px;
    padding: 2px;
    button {
      font-family: $font-secondary;
    }
    .heading-options-line-p {
      font-family: $font-secondary;
      font-size: 0.5rem;
      font-weight: 700;
      text-align: center;
      border-bottom: 0.5px solid #fff;
      margin: 0;
      margin-bottom: 2px;
    }
  }
  .right-layout {
    .right-layout-left {
      .score-card-match {
        border: 0.5px solid #fff;
        border-radius: 8px;
        padding: 5px 10px;
        .target-details {
          .target-details-p {
            font-family: $font-secondary;
            font-size: 1rem;
            font-weight: 700;
            margin: 0;
            .target-details-h6 {
              font-family: $font-secondary;
              font-size: 0.5rem;
              font-weight: 700;
              margin: 0;
            }
          }
          .vs2 {
            font-size: 1rem;
            color: rgba(231, 50, 147, 1);
            font-family: $font-secondary;
            font-weight: 400;
          }
        }

        .descrip {
          p {
            font-family: $font-secondary;
            font-size: 0.7rem;
            font-weight: 400;
            margin: 0;
          }
        }
      }
    }
    .right-layout-right {
      .current-scores {
        display: flex;
        justify-content: space-between;
        height: auto;
        border: 0.5px solid #fff;
        border-radius: 8px;
        padding: 5px 10px;
        padding-bottom: 3px;
        .current-scores-p {
          font-family: $font-secondary;
          font-size: 0.8rem;
          font-weight: 700;
          margin: 0;
          b {
            font-size: 10px;
          }
          .current-scores-h6 {
            font-family: $font-secondary;
            font-size: 0.4rem;
            font-weight: 400;
            margin: 0;
          }
        }
        span {
          display: flex;
        }
      }
    }
    .bigHeading {
      font-size: 0.8rem;
      font-family: $font-secondary;
      font-weight: 700;
      color: #fff;
      border-bottom: 0.5px solid #fff;
      margin: 0;
      margin-bottom: 5px;
    }
    .batting-cards {
      display: flex;
      flex-direction: column;
      align-items: center;

      .heading-options-line {
        border: $border-thin;
        border-color: rgba(255, 255, 255, 0.822);
        border-radius: 10px;
        padding: 4px;
        // margin-bottom: 5px;
        button {
          font-family: $font-secondary;
        }
        .heading-options-line-p {
          font-family: $font-secondary;
          font-size: 0.6rem;
          font-weight: 700;
          text-align: center;
          // margin-bottom: 10px;
          border-bottom: 0.5px solid #fff;
        }
      }
    }

    .bowling-cards {
      .heading-options-line {
        border: $border-thin;
        border-color: rgba(255, 255, 255, 0.822);
        border-radius: 10px;
        padding: 4px;
        button {
          font-family: $font-secondary;
        }
        .heading-options-line-p {
          font-family: $font-secondary;
          font-size: 0.6rem;
          font-weight: 700;
          text-align: center;
          // margin-bottom: 10px;
          border-bottom: 0.5px solid #fff;
        }
      }
    }
  }
}

.custom-modal {
  max-width: 75vw !important; /* 75% of viewport width */
  width: 75%;
  .modal-content {
    background-color: transparent;
  }
  .modal-body {
    padding: 0;

    #BattingScoreCard {
      .form-select {
        background-color: "#fff";
        width: 400px;
        border: none;
        box-sizing: none;
        color: $purple-color;
        box-shadow: none;
        font-weight: $font-weight-light;
        font-size: 0.7rem;

        option {
          background-color: $purple-color;
          color: $secondary-color;
          font-weight: $font-weight-light;
          font-size: 0.7rem;
          width: 400px;
        }
      }
      border-radius: 10px;
      .match-scoring-modal-heading {
        font-size: 1.2rem;
        font-weight: bold;
        font-family: $secondary-color;
        color: #fff;
        text-align: center;
        margin-bottom: 40px;
      }
      .hZZlND {
        border-radius: 0;
        width: fit-content;
      }

      .imMSkv {
      }

      .rdt_Table {
        background-color: transparent;
        border: 1px solid $secondary-color;
        border-radius: 0;

        .rdt_TableHeadRow {
          background-color: "#fff";
          color: $secondary-color;
          font-size: $font-size-small; // Reduced font size for table headings
          font-weight: $font-weight-bold;
          border-bottom: 1px solid $secondary-color;
          // min-height: 35px;

          .rdt_TableCol {
            border-right: 1px solid $secondary-color;
            justify-content: center;
            &:nth-child(1) {
              justify-content: start;
            }
            &:nth-child(2) {
              p {
                margin: 0;
                flex: 1; // Occupies all available space on the left
                text-align: start; // Ensures the text stays left-aligned
                word-break: break-word;
              }

              svg {
                margin-left: auto; // Pushes the icon to the far right
                cursor: pointer;
              }
            }
            span {
              display: none;
            }
          }
        }

        .rdt_TableBody {
          .rdt_TableRow {
            border-color: $secondary-color;
            background-color: transparent;
            // min-height: 35px;
          }
        }
      }

      .rdt_Pagination {
        // width: fit-content;
        background-color: transparent;
        color: $secondary-color;
        font-size: $font-size-small - 0.2rem; // Reduced font size for pagination
        padding: 5px; // Reduced padding for pagination
        svg {
          fill: $secondary-color;
        }
      }
    }
    #partnership {
      border-radius: 30px;
      .match-scoring-modal-heading {
        font-size: 1.2rem;
        font-weight: bold;
        font-family: $secondary-color;
        color: #fff;
        text-align: center;
        margin-bottom: 30px;
      }
      .partnership-section {
        background-color: #fff;
        font-family: $font-secondary;
        span {
          p {
            font-size: 1rem;
            margin: 0;
          }
          strong {
            font-size: 1rem;
            margin: 0;
          }
        }
        .partnership-section-highlights {
          /* Progress bar grows left to right (default Bootstrap behavior) */
          .progress-bar-ltr .progress-bar {
            direction: ltr;
          }

          /* Progress bar grows right to left */
          .progress-bar-rtl .progress-bar {
            direction: rtl;
          }
        }
      }
    }
    #phase-comparison {
      border-radius: 30px;
      .match-scoring-modal-heading {
        font-size: 32px;
        font-weight: bold;
        font-family: $secondary-color;
        color: #fff;
        text-align: center;
        margin-bottom: 30px;
      }
      .comparison-section {
        background-color: #fff;
        font-family: $font-secondary;
        border-radius: 20px;

        .hZZlND {
          border-radius: 0;
          width: fit-content;
        }

        .imMSkv {
        }

        .rdt_Table {
          background-color: transparent;
          border: 1px solid #000;
          border-radius: 0;
          margin-top: 20px;

          .rdt_TableHeadRow {
            background-color: $purple-color;
            color: $secondary-color;
            font-size: $font-size-small; // Reduced font size for table headings
            font-weight: $font-weight-bold;
            border-bottom: 1px solid #fff;
            // min-height: 35px;

            .rdt_TableCol {
              justify-content: center;
              &:nth-child(1) {
                justify-content: start;
              }
              &:nth-child(2) {
                p {
                  margin: 0;
                  flex: 1; // Occupies all available space on the left
                  text-align: start; // Ensures the text stays left-aligned
                  word-break: break-word;
                }

                svg {
                  margin-left: auto; // Pushes the icon to the far right
                  cursor: pointer;
                }
              }
              span {
                display: none;
              }
            }
          }

          .rdt_TableBody {
            .rdt_TableRow {
              border-color: $secondary-color;
              background-color: transparent;
              // min-height: 35px;
              .rdt_TableCell {
                border-right: 1px solid #000;
              }
            }
          }
        }
      }
    }
    #wagonWheel {
      border-radius: 30px;
      .match-scoring-modal-heading {
        font-size: 1.3rem;
        font-weight: bold;
        font-family: $secondary-color;
        color: #fff;
        text-align: center;
        margin-bottom: 30px;
      }
      p {
        font-size: 1.2rem;
        font-weight: bold;
        font-family: $secondary-color;
        color: #fff;
        text-align: center;
        margin-bottom: 10px;
      }
      .form-select {
        background-color: "#fff";
        width: 400px;
        border: none;
        box-sizing: none;
        color: $purple-color;
        box-shadow: none;
        font-weight: $font-weight-light;
        font-size: 0.7rem;

        option {
          background-color: $purple-color;
          color: $secondary-color;
          font-weight: $font-weight-light;
          font-size: 0.7rem;
          width: 400px;
        }
      }
    }
    #pitchMap {
      border-radius: 30px;
      .match-scoring-modal-heading {
        font-size: 1.3rem;
        font-weight: bold;
        font-family: $secondary-color;
        color: #fff;
        text-align: center;
        margin-bottom: 30px;
      }
      p {
        font-size: 1.2rem;
        font-weight: bold;
        font-family: $secondary-color;
        color: #fff;
        text-align: center;
        margin-bottom: 10px;
      }
      .form-select {
        background-color: "#fff";
        width: 400px;
        border: none;
        box-sizing: none;
        color: $purple-color;
        box-shadow: none;
        font-weight: $font-weight-light;
        font-size: 0.7rem;

        option {
          background-color: $purple-color;
          color: $secondary-color;
          font-weight: $font-weight-light;
          font-size: 0.7rem;
          width: 400px;
        }
      }
    }
    #selectBowlerModal {
  border-radius: 10px;
  .match-scoring-modal-heading {
    font-size: 1.2rem;
    font-weight: bold;
    font-family: $secondary-color;
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
  }
  .hZZlND {
    border-radius: 0;
    width: fit-content;
  }

  .imMSkv {
  }

  .rdt_Table {
    background-color: transparent;
    border: 1px solid $secondary-color;
    border-radius: 0;

    .rdt_TableHeadRow {
      background-color: "#fff";
      color: $secondary-color;
      font-size: $font-size-small; // Reduced font size for table headings
      font-weight: $font-weight-bold;
      border-bottom: 1px solid $secondary-color;
      // min-height: 35px;

      .rdt_TableCol {
        border-right: 1px solid $secondary-color;
        justify-content: center;
        &:nth-child(1) {
          justify-content: start;
        }
        &:nth-child(2) {
          p {
            margin: 0;
            flex: 1; // Occupies all available space on the left
            text-align: start; // Ensures the text stays left-aligned
            word-break: break-word;
          }

          svg {
            margin-left: auto; // Pushes the icon to the far right
            cursor: pointer;
          }
        }
        span {
          display: none;
        }
      }
    }

    .rdt_TableBody {
      .rdt_TableRow {
        border-color: $secondary-color;
        background-color: transparent;
        // min-height: 35px;
      }
    }
  }

  .rdt_Pagination {
    // width: fit-content;
    background-color: transparent;
    color: $secondary-color;
    font-size: $font-size-small - 0.2rem; // Reduced font size for pagination
    padding: 5px; // Reduced padding for pagination
    svg {
      fill: $secondary-color;
    }
  }
}
  }
}

#matchSummary {
  // @include page-layout();
  .heading1 {
    font-size: $font-size-base + 1rem;
    font-weight: $font-weight-bold;
    border: 0;
    margin: 0;
    margin-top: 30px;
    line-height: 1.2;
  }
  .heading2 {
    font-size: $font-size-base + 0.7rem;
    font-weight: 600;
    border: 0;
    margin: 0;
    margin-top: 15px;
    line-height: 1;
  }
  .heading3 {
    font-size: $font-size-base + 0.2rem;
    font-weight: 600;

    border: 0;
    margin: 0;
    margin-top: 15px;
    line-height: 1;
    p {
      font-size: $font-size-base;
      font-weight: 200;
      margin-top: 5px;
      color: #fff;
    }
  }
  .match-description-container {
    border: $border-thin;
    border-color: rgba(0, 0, 0, 0.822);
    border-radius: 10px;
    padding: 0 15px;
    background-color: #fff;
    .description-line {
      border-bottom: $border-thin;
      border-color: rgba(0, 0, 0, 0.822);
      padding: 5px 0;
    }
    .description-line:last-child {
      border-bottom: 0;
    }
    .description-line2 {
      border: 0;
    }
  }
  .row {
    .score-card {
      background-color: rgba(16, 14, 52, 1);
      border: $border-thin;
      border-color: rgba(255, 255, 255, 0.822);
      border-radius: 10px;
    }
    .player-of-match {
      p {
        font-size: 10px;
      }
    }
  }
  .table-container {
    width: 100%;

    padding: 20px 30px;
    border-radius: 10px;
    // border-color: rgba(0, 0, 0, 0.822);
    // max-width: 90vw;
    // overflow-x: auto;
    background-color: #fff;
    .heading {
      border-bottom: $border-thin;
      border-color: rgba(0, 0, 0, 0.822);
    }
    .hZZlND {
      border: 1px solid rgba(0, 0, 0, 0.822);
      border-radius: 0;
      .rdt_TableCol {
        border: 1px solid rgba(0, 0, 0, 0.822);
      }
      .rdt_TableHeadRow {
        background-color: $purple-color;
        color: $secondary-color;
        border-bottom-color: #ffffff;
        font-size: $font-size-base + 0.2rem;
        min-height: fit-content;
      }
      .rdt_TableRow {
        background-color: transparent;
        color: black;
        border-bottom-color: #000000;
        font-size: $font-size-base;
      }
      .rdt_TableCell {
        border-right: 1px solid rgba(0, 0, 0, 0.822);
      }
      //scrollbar style
      .rdt_TableBody::-webkit-scrollbar {
        width: 5px;
        height: 10px;
      }
      .rdt_TableBody::-webkit-scrollbar-track {
        background-color: $purple-color;
        border-radius: 5px;
      }
      .rdt_TableBody::-webkit-scrollbar-thumb {
        background-color: $secondary-color;
        border-radius: 5px;
      }
    }
  }

  .partnership {
    border-radius: 30px;
    .match-scoring-modal-heading {
      font-size: 1.2rem;
      font-weight: bold;
      font-family: $secondary-color;
      color: #fff;
      text-align: center;
      margin-bottom: 30px;
    }
    .partnership-section {
      background-color: #fff;
      font-family: $font-secondary;
      border-radius: 10px;

      span {
        p {
          font-size: 1rem;
          margin: 0;
        }
        strong {
          font-size: 1rem;
          margin: 0;
        }
      }
      .partnership-section-highlights {
        /* Progress bar grows left to right (default Bootstrap behavior) */
        .progress-bar-ltr .progress-bar {
          direction: ltr;
        }

        /* Progress bar grows right to left */
        .progress-bar-rtl .progress-bar {
          direction: rtl;
        }
      }
    }
  }

  .best-partnership {
    background-color: #fff;
    border-radius: 10px;
    .partnership-section {
      border-bottom: 1px solid #000;
    }
  }

  .spell-report {
    background-color: #fff;
    border-radius: 10px;
  }

  .highest-runs-over {
    background-color: #fff;
    border-radius: 10px;
    width: 50%;
    .highest-runs-over-section {
      .overs {
        width: 18px;
        height: 18px;
        color: #fff;
        @include flex-center();
        border-radius: 100px;
        font-weight: 700;
        font-family: $font-secondary;
        font-size: 0.8rem;
        margin-top: 5px;
        margin-right: 5px;
        padding: 0;
      }
    }
  }

  .ball-wise-boundaries {
    background-color: #fff;
    border-radius: 10px;
    width: 50%;
    .overs {
      width: 80px;
      height: 80px;
      color: #fff;
      @include flex-center();
      border-radius: 100px;
      font-weight: 700;
      font-family: $font-secondary;
      font-size: 1rem;
      margin-top: 5px;
      margin-right: 5px;
      padding: 0;
    }
  }

  .worm {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    .team-selector {
      margin-bottom: 10px;
      display: flex;
      gap: 15px;
      label {
        font-weight: bold;
        cursor: pointer;
        input {
          margin-right: 5px;
        }
      }
    }
  }

  .bar-chart {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    .team-selector {
      margin-bottom: 10px;
      display: flex;
      gap: 15px;
      label {
        font-weight: bold;
        cursor: pointer;
        input {
          margin-right: 5px;
        }
      }
    }
  }
  .comparison-bar-chart {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    .chart-title {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .pie-chart {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    .chart-title {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .overs-timeline {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
  }

  .wagon-wheel-area-runs {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
  }

  .player-stats {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;

    .player-stats-batting {
    }
  }
}


